import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      {
        path: "/",
        name: "page",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Home/HomePage.vue"),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
      {
        path: "/appointments/:option?",
        name: "appointments-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Appointments.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/video",
        name: "Video",
        component: () =>
          import(/* webpackChunkName: "details" */ "../components/video.vue"),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "profile-route",
        component: () =>
          import(/* webpackChunkName: "details" */ "../views/Home/Profile.vue"),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/dependants",
        name: "dependants-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Dependants.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/account",
        name: "account-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Profile/Account.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/payment",
        name: "payment-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Profile/Payment.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/insurance",
        name: "insure-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Profile/Insurance.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/messages",
        name: "messages-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Profile/Messages.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/bills",
        name: "bills-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Profile/Bills.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/vitals",
        name: "vitals-route",
        component: () =>
          import(/* webpackChunkName: "details" */ "../views/Home/Vitals.vue"),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/prescriptions",
        name: "prescriptions-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Prescriptions.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/labs",
        name: "labs-route",
        component: () =>
          import(/* webpackChunkName: "details" */ "../views/Home/Labs.vue"),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/imagings",
        name: "imagings-route",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Imagings.vue"
          ),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/covid-ready",
        name: "Covid-ready",
        component: () =>
          import(/* webpackChunkName: "details" */ "../views/Home/Covid.vue"),
        meta: {
          // guest: true,
          requiresAuth: true,
        },
      },
      {
        path: "/corona-tool",
        name: "corona-bot",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../components/coronaBot.vue"
          ),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
      {
        path: "/express-pay",
        name: "express-pay",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../views/Home/Profile/Bills/ExpressPay.vue"
          ),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
      {
        path: "/chat-tool",
        name: "chat-bot",
        component: () =>
          import(/* webpackChunkName: "details" */ "../components/chatBot.vue"),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
      {
        path: "/appoint-bot",
        name: "bot-page",
        component: () =>
          import(/* webpackChunkName: "details" */ "../components/apptBot.vue"),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
      {
        path: "/specialist-appoint-bot",
        name: "bot-page",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../components/specialistAppBot.vue"
          ),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
      {
        path: "/queue-bot",
        name: "bot-page",
        component: () =>
          import(
            /* webpackChunkName: "details" */ "../components/queueBot.vue"
          ),
        meta: {
          guest: true,
          // requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "*",
    name: "404-route",
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/UnderDevelopment.vue"),
    meta: {
      guest: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login-page",
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/Login.vue"),
    meta: {
      guest: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/signup",
    name: "signup-page",
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/SignUp.vue"),
    meta: {
      guest: true,
      // requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = { x: 0, y: 0 };
    // Keep scroll position when using browser buttons
    if (savedPosition) {
      position = savedPosition;
    }

    // Workaround for transitions scrolling to the top of the page
    // However, there are still some problems being fixed by the vue team
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      });
    });
  },
});

router.beforeEach((to, from, next) => {
  const isSignedIn = store.state.authStore.loggedIn;

  if (to.meta.guest) {
    next();
  } else {
    if (to.meta.requiresAuth) {
      const authorized = isSignedIn;
      if (authorized) {
        if (to.path === "/" && !store.state.authStore.userInfo) {
          next("/login");
        } else next();
      } else {
        next("/login");
      }
    }
  }
});

export default router;
