import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import authStore from "./auth";
import serviceStore from "./listService";
import visitsStore from "./visits";
import { getField, updateField } from "vuex-map-fields";
import env from "../../env";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrl: "https://api.v05.serenity.health", //https://api.v05.serenity.health //https://api.v05.serenity.health
    apiUrl: process.env.NODE_ENV === "production" ? env.baseUrlProduction : env.baseUrlTest,
    controlValue: {
      showLayout: false,
      enableDownload: true,
      previewModal: true,
      paginateElementsByHeight: 1200,
      manualPagination: false,
      filename: "Hee Hee",
      pdfQuality: 2,
      pdfFormat: "a4",
      pdfOrientation: "portrait",
      pdfContentWidth: "800px",
    },
  },
  mutations: {
    updateField,
  },
  getters: {
    getField,
  },
  actions: {},
  modules: {
    authStore,
    visitsStore,
    serviceStore,
  },
  plugins: [createPersistedState()],
});
