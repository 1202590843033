/* eslint-disable */
import moment from 'moment'

export default {
  filters: {
    /** 
     * Takes a datetime string and makes it a human readable datetime string
     * 
     * @param {String} dateTimeString
     * */
    _getReadableDate(dateTimeString) {
      let returnStr;
      const date = moment(dateTimeString);
      const today = moment().endOf("day");
      const tomorrow = moment()
        .add(1, "day")
        .endOf("day");
      if (date <= today && date >= today.startOf("day"))
        returnStr = "Today, " + date.format("HH:mm");
      else if (date < tomorrow && date >= tomorrow.startOf("day"))
        returnStr = "Tomorrow, " + date.format("HH:mm");
      else returnStr = date.format("ddd, Do MMM YYYY [at] HH:mm");
      return returnStr === "Invalid date" ? dateTimeString : returnStr;
    },
  },
  methods: {
    /**
     * Returns the secure HTTP and WS version of the passed resource
     * returns the passed resource untouched if already secure or unsopported protocol
     * 
     * @param {String} fullUrl URL to cross-check
     * @returns {String} secure version of the URL
     */
    _checkUrlAndMakeSecure(fullUrl) {
      if (typeof fullUrl === 'string') {
        let retLink = new String(fullUrl);
        // check if insecure HTTP
        if (retLink.startsWith("http:"))
          retLink = retLink.replace("http:", "https:");
        // check if insecure WS
        if (retLink.startsWith("ws:")) retLink = retLink.replace("ws:", "wss:");
        return retLink;
      }
      return fullUrl
    },

    /**
     * Takes a date string and returns age in years
     * 
     * @returns {String}
     * @param {String} dateString 
     */
    _getAgeFromString(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age + " years";
    },

    /**
     * Animate an element once
     * 
     * @param {Object} param0 specify elementSelector, animationName, callback fxn
     */
    _animateElement({ elementSelector = "", animationName = "", callback = "" }) {
      const node = document.querySelector(elementSelector)
      node.classList.add('animate__animated', 'animate__' + animationName)
      function handleAnimationEnd() {
        node.classList.remove('animate__animated', 'animate__' + animationName)
        node.removeEventListener('animationend', handleAnimationEnd)
        if (typeof callback === 'function') callback()
      }
      node.addEventListener('animationend', handleAnimationEnd)
    },

    /**
     * Generate label+input:radio pairs from a piped string representing the different values
     * 
     * @param {Object} data contains formName, labelCssClass. valuePipedString
     */
    _radioInputLabelGen(data) {
      const options = [];
      data.valuePipedString.split("|").forEach(val => {
        options.push({
          label: {
            for: `${data.formName}-${val}`,
            text: _.startCase(val),
            cssClass: data.labelCssClass
          },
          input: {
            type: "radio",
            name: data.formName,
            id: `${data.formName}-${val}`,
            value: val
          }
        });
      });
      return options;
    },
  }
}
