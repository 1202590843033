/* eslint-disable eol-last */
export default {
  namespaced: true,
  state: {
    loggedIn: false,
    userInfo: null,
    id: null,
    image: null,
  },

  actions: {
    unSetUserCode: ({ commit }) => {
      commit("UNSET_USER_CODE");
    },
  },

  getters: {
    isLoggedIn: (state) => state.loggedIn,
    // getToken: (state) => {
    //     return state.token
    //   },
  },

  mutations: {
    UNSET_USER_CODE(state) {
      state.userInfo = null;
      state.loggedIn = false;
      state.id = null;
    },
    // setToken(state, token) {
    //     state.token = token || state.token
    // },
    setUserInfo: (state, payload) => {
      state.userInfo = payload || state.userInfo;
    },
    setUserImage: (state, payload) => {
      state.image = payload || state.image;
    },
    setLoggedIn: (state, loggedIn) => {
      state.loggedIn = loggedIn;
    },
    setId: (state, payload) => {
      state.id = payload || state.id;
    },
  },
};
