var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"control-container"},[_c('h3',{staticClass:"control-header"},[_vm._v("Component Props Controls")]),_c('section',{staticClass:"item-container"},[_vm._l((_vm.controls),function(control,key){return _c('section',{key:key,staticClass:"control-item"},[_c('section',{staticClass:"control"},[_c('span',{staticClass:"item-label"},[_vm._v(_vm._s(control.label))]),_c('input',{class:control.inputType === 'checkbox'
                              ? 'input-checkbox'
                              : 'input-box',attrs:{"type":control.inputType},domProps:{"value":_vm.controlValue[control.vModel],"checked":_vm.controlValue[control.vModel]},on:{"input":function($event){return _vm.setInput(
                          $event,
                          control.vModel,
                          control.inputType
                      )},"change":function($event){return _vm.setInput(
                          $event,
                          control.vModel,
                          control.inputType
                      )}}})]),_c('section',{staticClass:"options-container"},[_c('section',{staticClass:"options-item"},[_c('span',{staticClass:"options-title"},[_vm._v("Type:")]),_c('span',{staticClass:"options-value"},[_vm._v(_vm._s(control.type))])]),_c('section',{staticClass:"options-item"},[_c('span',{staticClass:"options-title"},[_vm._v("Options:")]),_c('span',{staticClass:"options-value"},[_vm._v(_vm._s(control.options))])])]),(control.label === 'manual-pagination:')?_c('section',[_c('b',[_vm._v("Note:")]),_c('br'),_vm._v("When enabling this prop, the prop "),_c('br'),_c('b',[_vm._v("paginate-elements-by-height")]),_vm._v(" will not be used. Instead the pagination process will rely on the elements with a class \"html2pdf__page-break\" to know where to page break. ")]):_vm._e()])}),_vm._m(0)],2),_c('button',{staticClass:"generate-btn",attrs:{"disabled":_vm.isGenerating},on:{"click":function($event){return _vm.$emit('generateReport')}}},[_vm._v(_vm._s(_vm.isGenerating ? 'Loading...' : 'Generate PDF'))]),_c('section',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress",style:(("width: " + _vm.progress + "%;"))})]),(_vm.progress !== 0)?_c('section',{staticClass:"generating-label"},[_c('span',{staticClass:"label-title"},[_vm._v(_vm._s(_vm.progress === 100 ? 'PDF Generated' : 'Generating PDF'))]),_c('span',{staticClass:"label-progress"},[_vm._v(_vm._s((_vm.progress + " %")))])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b',[_vm._v("Note:")]),_c('br'),_vm._v("There is a prop called "),_c('b',[_vm._v("html-to-pdf-options")]),_vm._v(", if you set a value to this prop, the props below will be overridden: "),_c('br'),_c('br'),_c('b',[_vm._v("filename")]),_c('br'),_c('b',[_vm._v("pdf-quality")]),_c('br'),_c('b',[_vm._v("pdf-format")]),_c('br'),_c('b',[_vm._v("pdf-orientation")]),_vm._v(". "),_c('br'),_c('br'),_vm._v("Any value inputed to those props above will have no effect. ")])}]

export { render, staticRenderFns }