<template>
  <div class="Login">
    <div class="mb-5">
      <!-- image -->
      <div style="max-height: 260px">
        <img
          src="@/assets/Home.svg"
          alt="intro"
          style="width: 100%; height: 90%"
        />
      </div>
      <div class="close">
        <router-link to="/">
          <i class="fa fa-times shadow"></i>
        </router-link>
      </div>
      <!-- greeting -->
      <div class="row">
        <div class="col">
          <!-- <span style="font-size: 1rem">{{ getGreeting }}</span> -->
          <!-- <span style="font-size: 1.2rem">Good {{ getTimeOfDay }}</span> -->
          <!-- <br /> -->
          <b
            v-if="!verify"
            style="
              font-size: 2rem;
              text-overflow: ellipsis;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              display: block;
            "
            >Welcome!</b
          >
          <b
            v-else
            style="
              font-size: 2rem;
              text-overflow: ellipsis;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              display: block;
            "
            >Almost there!</b
          >
          <span v-if="!verify">Please enter number to continue</span>
          <span v-else class="text-justify mx-2">
            Please verify your number by entering the six digit that has been
            sent to your phone
          </span>
          <br />
        </div>
      </div>
      <div class="container">
        <form>
          <div class="row m-0 mt-4">
            <div class="col-12" v-if="!verify">
              <input
                type="text"
                name="mobile-inp"
                id="mobile-inp"
                class="form-inp"
                placeholder="mobile"
                v-model.trim="form.mobile"
                required
              />
            </div>
            <div class="col-12" v-else>
              <input
                type="text"
                name="otp-code"
                class="form-inp"
                placeholder="code"
                v-model.trim="form.password"
                required
              />
            </div>
            <div class="col-12" v-if="!verify">
              <div class="row justify-content-between px-3">
                <small style="font-size: 0, 8rem">
                  Don't have an account?
                  <router-link to="/signup" class="text-info" replace
                    >Sign Up</router-link
                  >
                </small>
                <small style="font-size: 0, 8rem">
                  <!-- <a href class="text-info">Forgot your password?</a> -->
                </small>
              </div>
            </div>
            <div class="col-12 mt-3" v-else>
              <div class="row justify-content-between px-3">
                <small @click="changeNumber()">
                  <router-link to="#" class="text-info"
                    >Change number</router-link
                  >
                </small>
                <small @click="validateForm()">
                  <router-link to="#" class="text-info"
                    >Send code again</router-link
                  >
                </small>
              </div>
            </div>
            <div class="col-12 mt-5 text-right">
              <button
                type="submit"
                @click.prevent="login"
                v-if="!verify"
                class="loginBtn"
                :disabled="loading"
              >
                <i
                  v-if="loading"
                  :class="[
                    'fa',
                    'fa-spin',
                    'fa-spinner',
                    loading ? 'loading-width' : '',
                  ]"
                ></i>
                <template v-else>
                  <i class="fa fa-arrow-right"></i>
                </template>
              </button>
              <button
                type="submit"
                @click="goLive"
                v-if="verify"
                class="btn btn-info"
              >
                Verify Code
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/network/Base.js";
import login from "@/network/user/signin.js";
import moment from "moment";

export default {
  name: "login",
  components: {},
  data() {
    return {
      loading: false,
      verify: false,
      form: {
        mobile: "",
        password: "",
      },
      user: "",
      code: "",
    };
  },
  computed: {
    getTimeOfDay() {
      const hr = new Date().getHours();
      if (hr < 12) return "Morning";
      else if (hr < 17) return "Afternoon";
      else return "Evening";
    },
  },
  methods: {
    goLive() {
      if (this.code.code !== this.form.password) {
        // this.$snotify.success("Login successful");
        this.$store.commit("authStore/setLoggedIn", true);
        this.$store.commit("authStore/setUserInfo", this.user);
        this.$store.commit("authStore/setId", this.user.szpatientid);
        this.$router.push("/");
        // this.$gtag.event("login", {
        //   event_category: "engagement",
        //   event_label: "method",
        //   value: 1,
        // });
      } else {
        this.$snotify.error("Code incorrect");
      }
    },
    changeNumber() {
      this.verify = false;
    },
    validateForm() {
      // let userlog = user
      http()
        .post("/user/sendotpcode", {
          sz_phoneno: this.form.mobile,
          phone: this.form.mobile,
        })
        .then((data) => {
          // this.$Progress.finish()
          let empty = null;

          let userobject = null;
          userobject = data.data;
          console.log(userobject);
          if (userobject.status == true) {
            this.code = userobject;
            this.verify = true;
          } else {
            // this.$swal('Login Error', "Incorrect email & password!", 'error')
            this.$snotify.error("Code wasn't sent");
            // e.loading = false
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$Progress.finish()
          if (error.response) {
            // this.$Progress.finish()
            if (error.response.status === 500) {
              //    return this.$swal('System Error', 'Error occurred during authentication processing!', 'error')
              return this.$snotify.error(
                "An error occured whiles authenticating"
              );
            } else if (error.response.status === 200) {
              // this.$swal('Login Error', "Incorrect email & password!", 'error')
              return this.$snotify.error("Incorrect number");
            }
            // return this.$swal('Error', 'Incorrect email & password!!', 'error')
            return this.$snotify.error("Incorrect number");
          }
          // return this.$swal('Error', 'Please check you internet connection', 'error')
          return this.$snotify.error(
            "Could not sign you in. Check your internet connection!"
          );
        })
        .finally(() => {
          this.isSignedIn = false;
          this.loading = false;
        });
    },
    login() {
      this.loading = true;
      // login.userSignIn(this, http(), this.form);
      http()
        .post("/patient/checkexistence", {
          sz_phoneno: this.form.mobile,
        })
        .then(async (data) => {
          // Fetch user profile info
          const response = await http().post("/patient/get", {
            szpatientid: data.data.sz_userid,
          });
          // this.$Progress.finish()
          let empty = null;
          this.loading = false;

          let userobject = null;
          userobject = response.data;
          userobject.sz_userid = data.data.sz_userid;
          if (userobject) {
            this.user = userobject;
            this.validateForm();
          } else {
            // this.$swal('Login Error', "Incorrect email & password!", 'error')
            this.$snotify.error("Please you don't have an account with us");
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          // this.$Progress.finish()
          if (error.response) {
            // this.$Progress.finish()
            if (error.response.status === 500) {
              //    return this.$swal('System Error', 'Error occurred during authentication processing!', 'error')
              return this.$snotify.error(
                "An error occured whiles authenticating"
              );
            } else if (error.response.status === 200) {
              // this.$swal('Login Error', "Incorrect email & password!", 'error')
              return this.$snotify.error("Incorrect number!");
            }
            // return this.$swal('Error', 'Incorrect email & password!!', 'error')
            return this.$snotify.error("Incorrect number");
          }
          // return this.$swal('Error', 'Please check you internet connection', 'error')
          return this.$snotify.error(
            "Could not sign you in. Check your internet connection!"
          );
        })
        .finally(() => {
          this.isSignedIn = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.Login {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}
.close {
  position: absolute;
  top: 40px;
  left: 40px;
  color: red;
  cursor: pointer;
}
.form-inp {
  border: none;
  border-bottom: 2px solid grey;
  width: 100%;
  height: 3rem;
  padding: 1rem 4px;
  margin-bottom: 8px;
  background: transparent;
  font-size: 1.3rem;
}
.loginBtn {
  background: linear-gradient(
    270deg,
    rgba(9, 220, 68, 0.48) 0%,
    rgba(20, 178, 247, 0.48) 100%
  );
  border-radius: 50%;
  border: 0px;
  width: 45px;
  height: 45px;
  color: white;
}
</style>
