/* eslint-disable eol-last */
import axios from "axios";
import env from "../../env";

export default () => {
  return axios.create({
    baseURL: process.env.NODE_ENV === "production" ? env.baseUrlProduction : env.baseUrlTest,
    // baseURL: "https://api.v05.serenity.health", //https://api.v05.serenity.health //https://api.v05.serenity.health
    // responseType: 'json',
    timeout: 30000,
    // headers: {
    //     'Access-Control-Allow-Origin': 'www.madakoraa.com',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // }
  });
};
