<template>
  <div class="home">
    <div class="page-container">
      <header id="app-header">
        <div>
          <nav
            id="app-navbar"
            class="navbar navbar-expand-lg navbar-light bg-light fixed-top"
          >
            <div class="container">
              <!-- <a href="/" class="navbar-brand" title="Home"> -->
              <span
                @click="goBack"
                class="mr-4"
                v-if="shouldShowNavBarBackIcon"
              >
                <i
                  class="fa fa-arrow-left"
                  style="color: #45d4ff; font-size: 24px"
                ></i>
              </span>

              <img
                src="@/assets/serenity-logo.svg"
                alt="Serenity"
                width="125"
                class="logo-brand navbar-brand m-0"
              />
              <!-- </a> -->
              <div
                v-if="loggedIn"
                class="navbar-toggler p-0"
                type="button"
                @click="routeProfile"
                style="border-radius: 24px"
                aria-label="Toggle navigation"
              >
                <!-- <b-avatar variant="info" :text="userInfo.firstname.charAt(0) + userInfo.othernames.charAt(0) + userInfo.lastname.charAt(0)"></b-avatar> -->
                <div
                  id="imagePreview"
                  style="
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                  "
                  :style="{ 'background-image': `url(${image})` }"
                >
                  <vue-initials-img
                    v-if="!image"
                    :name="userInfo.szfirstname + ' ' + userInfo.szlastname"
                    style="width: 30px; height: 30px; border-radius: 50%"
                  />
                </div>
                <!-- <i v-if="!loggedIn" class="fa fa-bars"></i> -->
              </div>
              <div v-else class="ml-auto mx-2">
                <div class="row">
                  <b-button
                    variant="light"
                    class="px-3 mx-1"
                    @click="login"
                    style="border-radius: 20px; font-size: 15px"
                    >login</b-button
                  >
                  <b-button
                    variant="info"
                    class="px-3 mx-1"
                    @click="signup"
                    style="border-radius: 20px; font-size: 15px"
                    >Sign Up</b-button
                  >
                </div>
              </div>
              <div v-if="loggedIn" class="ml-auto d-lg-block d-none d- mx-2">
                <div class="row">
                  <b-button
                    variant="light"
                    class="px-3 mx-1"
                    @click="routeHome"
                    style="border-radius: 20px; font-size: 15px"
                    >Home</b-button
                  >
                  <b-button
                    variant="light"
                    class="px-3 mx-1"
                    @click="routeMessage"
                    style="border-radius: 20px; font-size: 15px"
                    >Messages</b-button
                  >
                  <b-button
                    variant="light"
                    class="pl-1 pr-2 py-0 mx-1"
                    @click="routeProfile"
                    style="
                      border-radius: 20px;
                      font-size: 14px;
                      background-color: #f6f7f9;
                    "
                    ><span
                      id="imagePreview"
                      style="
                        width: 15px;
                        height: 15px;
                        border-radius: 100%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                      "
                      :style="{ 'background-image': `url(${image})` }"
                    >
                      <vue-initials-img
                        v-if="!image"
                        :name="userInfo.szfirstname + ' ' + userInfo.szlastname"
                        style="width: 30px; height: 30px; border-radius: 50%"
                      />
                    </span>
                    <span class="ml-2 text-muted" style="font-size: 12px"
                      >{{ userInfo.szfirstname }}
                      <!-- <i class="pl-1 pr-1 mt-2 fa fa-angle-down"></i> -->
                    </span></b-button
                  >
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div class="home-main">
        <transition
          appear
          name="custom-classes-transition"
          enter-active-class="animated fadeIn"
        >
          <router-view />
        </transition>
      </div>
    </div>
    <!-- <Footer class="fixed-bottom"/> -->
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { mapState } from "vuex";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      // isNavOpen: false,
      path: "https://api.whatsapp.com/send?phone=233540736077",
    };
  },
  methods: {
    goBack() {
      if (
        this.$router.currentRoute.path.includes("/payment") ||
        this.$router.currentRoute.path.includes("/insurance") ||
        this.$router.currentRoute.path.includes("/account") ||
        this.$router.currentRoute.path.includes("/bills")
      )
        this.$router.go(-1);
      else this.$router.push("/");
    },
    routeProfile() {
      this.$router.push("/profile");
    },
    routeHome() {
      this.$router.push("/");
    },
    routeMessage() {
      this.$router.push("/messages");
    },
    logout() {
      this.$store.dispatch("authStore/unSetUserCode");
      // this.$store.dispatch('unSetToken')
      this.$router.push("/login");
    },
    login() {
      this.$router.push("/login");
    },
    signup() {
      this.$router.push("/signup");
    },
    // openNav() {
    //     this.isNavOpen = !this.isNavOpen
    //     if(this.isNavOpen == true){
    //         document.getElementById("mySidenav").style.width = "250px"
    //     } else{
    //         document.getElementById("mySidenav").style.width = "0";
    //     }
    //     },
    // closeNav() {
    //     document.getElementById("mySidenav").style.width = "0";
    // },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.authStore.userInfo,
      loggedIn: (state) => state.authStore.loggedIn,
      image: (state) => state.authStore.image,
    }),
    shouldShowNavBarBackIcon() {
      console.log(this.$route.path);
      const routes = ["/", "/login"];
      return !routes.includes(this.$route.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  font-weight: bold;
  // text-transform: uppercase;
  background: linear-gradient(to right, rgb(4, 238, 250) 18%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.router-link {
  color: rgb(92, 91, 91);
  .fa::before {
    font-size: 20px;
  }
}
.router-link-exact-active {
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-bottom: 2px solid aquamarine;
  .fa::before {
    font-size: 25px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
