/* eslint-disable eol-last */
export default {
  namespaced: true,
  state: {
    specialties: null,
    listChildren: null,
    listGPtiers: null,
    listCVtiers: null,
    listGP: null,
    listCV: null,
    select: "",
    covidReady: null,
  },

  actions: {
    Specialty: ({ commit }, load) => {
      commit("setSpecialties", load);
    },
  },

  getters: {},

  mutations: {
    setSpecialties: (state, payload) => {
      state.specialties = payload || state.specialties;
    },
    setChildren: (state, payload) => {
      state.listChildren = payload || state.listChildren;
    },
    setSelect: (state, payload) => {
      state.select = payload || state.select;
    },
    setGPtiers: (state, payload) => {
      state.listGPtiers = payload || state.listGPtiers;
    },
    setCovid: (state, payload) => {
      state.covidReady = payload;
    },
    setCVtiers: (state, payload) => {
      state.listCVtiers = payload || state.listCVtiers;
    },
    setGP: (state, payload) => {
      state.listGP = payload || state.listGP;
    },
    setCV: (state, payload) => {
      state.listCV = payload || state.listCV;
    },
  },
};
