import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import global from "./pulgins/globalMixins";
// import "./registerServiceWorker";
import VueSnotify from "vue-snotify";
import ProgressBar from "vue-progressbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueInitialsImg from "vue-initials-img";

import BootstrapVue from "bootstrap-vue";
import VueHtmlToPaper from "vue-html-to-paper";
var _ = require("lodash");
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: { id: "UA-176409533-1" },
  },
  router
);
Vue.use(require("vue-moment"));
Vue.use(BootstrapVue);
Vue.use(VueInitialsImg);
Vue.use(global);
// app.js
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

library.add(faUserSecret);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(ProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "2px",
});

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "bootstrap/dist/css/bootstrap.css",
    "@/assets/scss/printing.css",
    "bootstrap-vue/dist/bootstrap-vue.css",
  ],
};

Vue.use(VueSnotify, {
  toast: {
    showProgressBar: false,
  },
});

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
