<template>
  <div id="app">
    <nav class="navbar bg-light sticky-top" v-show="installBtn">
      <div>
        <small>
          Install
          <b>Serenity</b> on your device.
        </small>
      </div>
      <div class="row justify-content-end m-0">
        <b-button variant="info" class="text-right" @click="installer()"
          >Install</b-button
        >
      </div>
    </nav>
    <transition
      appear
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
    >
      <router-view />
    </transition>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import PdfContent from "@/components/PdfContent";
// import VueHtml2pdf from '@/components/VueHtml2pdf'
import ControlsContainer from "@/components/ControlsContainer";
import VueHtml2pdf from "vue-html2pdf";
import http from "@/network/Base.js";
// import VueHtml2pdf from 'vue-html2pdf-test'
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";

export default {
  data() {
    return {
      installBtn: false,
      installer: undefined,
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
      daylist: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    };
  },
  components: {
    VueHtml2pdf,
    PdfContent,
    ControlsContainer,
  },
  methods: {
    async downloadPdf() {
      if (!(await this.validateControlValue())) return;

      this.$refs.html2Pdf.generatePdf();
    },
  },
  // computed:{
  //   ...mapState({
  //     Reg: state => state.reg,
  //   }),
  // },
  created() {
    let installPrompt;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();

      installPrompt = e;
      this.installBtn = true;
    });

    this.installer = () => {
      this.installBtn = false;

      installPrompt.prompt();

      installPrompt.userChioce.then((result) => {
        installPrompt = null;
      });
    };
    http()
      .get("/provider/healthservices?specialty=sars-cov-2")
      .then((response) => {
        let listTiers = response.data[0];
        // console.log(listTiers);
        // let listCVtiers = [];
        // listTiers.forEach((list, index) => {
        //   listCVtiers.push({
        //     tag: "option",
        //     "cf-label":
        //       list.display + " " + "(" + list.charge + list.currency + ")",
        //     value: list.display,
        //   });
        // });
        // this.$store.commit("serviceStore/setCVtiers", listCVtiers);
        this.$store.commit("serviceStore/setCV", listTiers);
        // let greetingsTags[1].children = listChildren;
      })
      .catch((error) => {
        console.log(error);
      });
    http()
      .get("employees?is_specialist=true")
      .then((response) => {
        // var today = new Date();
        // var t_day = today.getUTCDay();
        // let availableDay = null;
        let listSpecialist = response.data;
        // listSpecialist.forEach((specialist) => {
        //   specialist.employee_availability.forEach((available) => {
        //     employee_availability_days_of_week.forEach((day) => {
        //       if (this.daylist[t_day] == day.day) {
        //         availableDay = day.day;
        //         specialist.open = {
        //           day: day.day,
        //           otime: specialist.opening_time,
        //           ctime: specialist.closing_time,
        //         };
        //       } else if (!specialist.open) {
        //         specialist.open = {
        //           day: "Not Today",
        //           otime: "00:00:00",
        //           ctime: "00:00:00",
        //         };
        //       }
        //     });
        //   });
        // });
        this.$store.commit("serviceStore/setSpecialties", listSpecialist);
        // let greetingsTags[1].children = listChildren;
      })
      .catch((error) => {
        console.log(error);
      });
    http()
      .get(
        "/provider/healthservices?clinic_name=virtual-care&specialty=general%20practice"
      )
      .then((response) => {
        let listTiers = response.data[0];
        this.$store.commit("serviceStore/setGP", listTiers);
        // let greetingsTags[1].children = listChildren;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material.css";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
