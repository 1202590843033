<template>
  <section class="pdf-content">
    <section class="report-info">
      <section class="image-container">
        <img
          class="user-image"
          src="https://cdn.glitch.com/1d1d7c79-abe4-4c95-b135-3a111632f4d8%2Fserenity-nyaho-logo.png?v=1589272118531"
          alt="User Image"
          ref="userImage"
        />
      </section>

      <section class="user-details">
        <h4 class="user-name">Lab Test</h4>

        <span class="detail-container">
          <span class="detail-label">Requested Lab Type:</span>
          SARS-COV-2
        </span>

        <span class="detail-container">
          <span class="detail-label">Patient Name:</span>
          Kwame Eugene
        </span>

        <span class="detail-container">
          <span class="detail-label">Mobile</span>
          0274425599
        </span>

        <span class="detail-container">
          <span class="detail-label">Date and Time</span>
          November 11, 2019 1:00 PM - 2:23 PM
        </span>

        <span class="detail-container">
          <span class="detail-label">Test Result:</span>

          <a href>Negative</a>
        </span>
        <span class="detail-container">
          <span class="detail-label">Conclusion:</span>
          Patient did not test positive for the Corona Virus lab test
        </span>
      </section>
    </section>

    <div class="html2pdf__page-break" />
  </section>
</template>

<script>
export default {
  data() {
    return {
      series: [
        {
          name: "Level",
          data: [
            { y: 1, x: "11/11/2019 13:00:00" },
            { y: 1, x: "11/11/2019 13:01:00" },
            { y: 2, x: "11/11/2019 13:02:00" },
            { y: 4, x: "11/11/2019 13:03:00" },
            { y: 5, x: "11/11/2019 14:04:00" },
          ],
        },
        {
          name: "Experience",
          data: [
            { y: 5, x: "11/11/2019 13:00:00" },
            { y: 5, x: "11/11/2019 13:01:00" },
            { y: 10, x: "11/11/2019 13:02:00" },
            { y: 12, x: "11/11/2019 13:03:00" },
            { y: 20, x: "11/11/2019 14:04:00" },
          ],
        },
        {
          name: "Gold",
          data: [
            { y: 10, x: "11/11/2019 13:00:00" },
            { y: 10, x: "11/11/2019 13:01:00" },
            { y: 20, x: "11/11/2019 13:02:00" },
            { y: 30, x: "11/11/2019 13:03:00" },
            { y: 50, x: "11/11/2019 14:04:00" },
          ],
        },
      ],

      chartOptions: {
        dataLabels: {
          enabled: false,

          style: {
            colors: ["#fff"],
          },
        },

        stroke: {
          curve: "smooth",
        },

        chart: {
          toolbar: {
            show: false,

            tools: {
              download: false,
            },
          },

          animations: {
            enabled: false,

            animateGradually: {
              enabled: false,
            },

            dynamicAnimation: {
              enabled: false,
            },
          },
        },

        tooltip: {
          enabled: true,
          // theme: true,
          x: {
            show: true,
            format: "MMM dd, yyyy HH:mm",
          },
        },

        legend: {
          show: true,
          labels: {
            useSeriesColors: false,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },

        xaxis: {
          labels: {
            style: {
              // colors: '#fff',
            },
          },

          type: "datetime",
        },

        yaxis: {
          labels: {
            style: {
              // color: '#fff',
            },

            formatter: (value) => {
              return value;
            },
          },

          type: "text",
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;

  .report-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0px;

    .image-container {
      align-self: start;
      width: auto;
      height: 75px;
      overflow: hidden;
      position: relative;
      margin-bottom: 25px;

      .user-image {
        height: 100%;
        min-width: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
        opacity: 0.5;
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .user-name {
        margin-bottom: 10px;
        flex-shrink: 0;
      }

      .detail-container {
        font-size: 13px;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .detail-label {
          font-weight: 600;
          margin-right: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .chart-container {
    width: 100%;
    height: 320px;
    padding: 20px;
    pointer-events: none;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
</style>